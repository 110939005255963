import { Link } from 'gatsby'
import React from 'react'

import Cover from '../components/Cover/Cover'
import islandImage from '../../static/island.jpg'
import Layout from "../components/layout"
import SEO from "../components/seo"


export default function FourZeroFour () {
  const getCoverBackgroundStyle = (url) => {
    return `
      url(${url}) #B7DBCF no-repeat center center fixed
    `
  }

  return <Layout>
    <SEO title={'Page Not Found'}></SEO>
    <Cover backgroundStyle={getCoverBackgroundStyle(islandImage)}>&nbsp;</Cover>
    <p>We're not sure what winds brought you here, but you seem to have been blown off course.</p>
    <p>The page you are looking for doesn't exist.</p>
    <p><Link to="/">Click here to go back to the homepage.</Link></p>
  </Layout>
}